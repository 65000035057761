var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{class:[
    'header-wallets',
    {
      'header-wallets_showed-list': _vm.showedList,
      'header-wallets_hidden-list': !_vm.showedList,
    },
  ],attrs:{"id":_vm.htmlElementId.headerWallets}},[_c('div',{staticClass:"header-wallets__inner"},[(_vm.topItem)?_c('Wallet',{staticClass:"header-wallets__top-currency position-relative",attrs:{"currency-color":_vm.topItem.currencyColor,"bg-color":_vm.topItem.bgColor,"currency":_vm.topItem.currency,"balance":_vm.topItem.balance,"card-balance":_vm.topItem.cardBalance,"disabled-refill":_vm.disabledRefill,"rounded":false},scopedSlots:_vm._u([{key:"append",fn:function(){return [_c('v-btn',{directives:[{name:"click-outside",rawName:"v-click-outside",value:(_vm.onClickOutside),expression:"onClickOutside"}],staticClass:"header-wallets__top-currency-arrow my-auto",attrs:{"width":_vm.$vuetify.breakpoint.lgAndUp ? 20 : 16,"height":_vm.$vuetify.breakpoint.lgAndUp ? 20 : 16,"x-small":"","icon":"","color":"grey"},on:{"click":function($event){return _vm.toggleShowList()}}},[_c('v-icon',{attrs:{"size":_vm.$vuetify.breakpoint.lgAndUp ? 18 : 14}},[_vm._v(" $arrowDropDown ")])],1)]},proxy:true}],null,false,2394976001)}):_vm._e(),_c('div',{staticClass:"header-wallets__list"},_vm._l((_vm.menuItems),function(wallet,walletIndex){return _c('Wallet',{key:walletIndex,staticClass:"header-wallets__wallet",attrs:{"currency-color":wallet.currencyColor,"bg-color":wallet.bgColor,"currency":wallet.currency,"balance":wallet.balance,"card-balance":wallet.cardBalance,"disabled-refill":_vm.disabledRefill,"rounded":false,"content-class":"cursor-pointer user-select-none"},on:{"click:content":function($event){return _vm.setActiveWalletCurrency(wallet.currency)}}})}),1)],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }