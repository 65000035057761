import { Component, Mixins } from "vue-property-decorator";
import debounce from "lodash.debounce";
import { Currency } from "@/types/currency";
import { userModule } from "@store/namespaces";
import { UserActions, UserGetters } from "@store/modules/user/types";
import { UserWebPreferencesKey } from "@/types/user";

import HeaderWalletsMixin from "../../common/mixins/header-wallets.mixin";
import Wallet from "../../common/components/Wallet/Wallet.vue";

@Component({
  components: {
    Wallet,
  },
})
export default class MobileHeaderWallets extends Mixins(HeaderWalletsMixin) {
  @userModule.Action("fetchUserWebPreferences")
  private readonly fetchUserWebPreferencesAction!: UserActions["fetchUserWebPreferences"];
  @userModule.Getter("userWebPreferences")
  private readonly userWebPreferencesGetter!: UserGetters["userWebPreferences"];
  @userModule.Action("updateUserWebPreferences")
  private readonly updateUserWebPreferencesAction!: UserActions["updateUserWebPreferences"];

  private showedList = false;
  private activeWalletCurrency = Currency.EUR;

  private get topItemIndex() {
    this.$vuetify.breakpoint.lgAndUp;
    return Math.max(
      this.items.findIndex(
        (item) => item.currency === this.activeWalletCurrency
      ),
      0
    );
  }

  private get topItem() {
    return this.items[this.topItemIndex];
  }

  private get menuItems() {
    return this.items.filter((_, index) => index !== this.topItemIndex);
  }

  private toggleShowList(showed = !this.showedList) {
    this.showedList = showed;
  }

  private setActiveWalletCurrency(currency: Currency) {
    this.activeWalletCurrency = currency;
  }

  private onClickOutside() {
    this.toggleShowList(false);
  }

  private async initWebPreferences() {
    await this.fetchUserWebPreferencesAction({
      key: UserWebPreferencesKey.HEADER_WALLETS,
    });

    const { value } = this.userWebPreferencesGetter({
      key: UserWebPreferencesKey.HEADER_WALLETS,
    });

    const userWebPreferences = value as Partial<{
      activeWalletCurrency: Currency;
    }> | null;

    this.activeWalletCurrency =
      userWebPreferences?.activeWalletCurrency ?? this.activeWalletCurrency;

    this.$watch(
      () => {
        return this.activeWalletCurrency;
      },
      debounce(() => {
        this.updateUserWebPreferencesAction({
          key: UserWebPreferencesKey.HEADER_WALLETS,
          value: {
            activeWalletCurrency: this.activeWalletCurrency,
          },
        });
      }, 500)
    );

    this.$watch(
      () => {
        return this.items.length;
      },
      (itemsLength) => {
        if (!itemsLength) return;

        this.activeWalletCurrency =
          this.items.find((item) => item.currency === this.activeWalletCurrency)
            ?.currency || this.items[0].currency;
      }
    );
  }

  private mounted() {
    this.initWebPreferences();
  }
}
